import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore } from '@/store';
const routeGuardAdmin = async (to, from, next) => {
    if (!mainStore.hasAdminAccess) {
        next('/main');
    }
    else {
        next();
    }
};
let Admin = class Admin extends Vue {
    beforeRouteEnter(to, from, next) {
        routeGuardAdmin(to, from, next);
    }
    beforeRouteUpdate(to, from, next) {
        routeGuardAdmin(to, from, next);
    }
};
Admin = __decorate([
    Component
], Admin);
export default Admin;
